@font-face {
  font-family: "Gotham-Bold";
  src: url(../fonts/Gotham-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Gotham-Book";
  src: url(../fonts/Gotham-Book.otf) format("opentype");
}

@font-face {
  font-family: "Gotham-Light";
  src: url(../fonts/Gotham-Light.otf) format("opentype");
}

@font-face {
  font-family: "Gotham-Medium";
  src: url(../fonts/Gotham-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Gotham-Ultra";
  src: url(../fonts/Gotham-Ultra.otf) format("opentype");
}

@font-face {
  font-family: "Gotham-Narrow-Black";
  src: url(../fonts/GothamNarrow-Black.otf) format("opentype");
}
@font-face {
  font-family: "Gotham-Narrow-Book";
  src: url(../fonts/GothamNarrow-Black.otf) format("opentype");
}
@font-face {
  font-family: "Gotham-Narrow-Thin";
  src: url(../fonts/GothamNarrow-Thin.otf) format("opentype");
}
@font-face {
  font-family: "Gotham-Narrow-Ultra";
  src: url(../fonts/GothamNarrow-Ultra.otf) format("opentype");
}
