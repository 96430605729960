@import "./font.scss";
@import "../../../node_modules/antd/dist/antd.css";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro";
  background-color:#f6f6f6;
}

p {
  margin: 0;
}

.splash-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .splash-icon {
    width: 350px;
  }
}

.ant-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  // min-height: 100vh;
  background-color:#f6f6f6;

  .header-title{
    @media screen and (max-width: 400px) {
      max-width: 300px;
      text-align: center;
    }
  }
  .ant-layout-header {
    position: fixed;
    top: 0;
    z-index: 1;
    background: white;
    box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.03);
    width: 100%;
    padding: 0px;
    height: 64px;
    display: flex;
    justify-content: center;


    .btn-back {
      cursor: pointer;
      position: absolute;
      left: 0;
      width: 20px;
      margin-left: 15px;
    }
    .btn-share {
      cursor: pointer;
      position: absolute;
      right: 0;
      width: 20px;
      margin-right: 20px;
      opacity: 0.9;
    }

    .logo {
      width: auto;
      height: 30px;
    }

    &.header-bottom {
      position: fixed;
      top: auto;
      bottom: 0;
      padding: 0 10px;

      .header-icon-section {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        line-height: 100%;
        width: 100%;

        .header-menu {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;

          .header-icon {
            width: 25px;
          }

          .header-text {
            font-size: 10px;
            font-weight: bold;
            color: black;

            &.active {
              color: #EA217A;
            }
          }
        }
      }
    }
  }
  .header-page{
    margin-bottom: 40px;
  }
  .header-outlet{
    background: linear-gradient(97.47deg, #9E1F64 -11.3%, #EC1652 95.68%);
    padding: 0 20px;
    z-index: 999;
    border-bottom-right-radius:10px;
    border-bottom-left-radius:10px;
    text-align: center;
  }
  .header-search{
    margin-left: 50px;
    border-radius: 10px;
  }
  .anticon-search{
    color: #BBBBBB;
    font-weight: 600;
  }
  .ant-input-affix-wrapper{
    border-radius: 5px;
  }
  .swiper-promo{
    .swiper-slide{
    width: 75%;
    }
  }
  .swiper-wrapper {
    width: 350px;
  }

  .promo-wording{
    background: linear-gradient(101.15deg, #EE4C24 3.75%, #EA217A 96.16%);
    border-radius: 12px;

    .promo-contain{
      padding: 10px 35px 5px 15px;
    }
    .promo-text{
      font-family: 'Source Sans Pro';
      color: white;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 7px;
    }
    .promo-button{
      background: #FFFFFF;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      width: 76px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      span{
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #235FF6;
      }
    }
    .icon-image{
      width: 100%;
    }
  }
  .belanja-murah-row{
    background-image: url("../img/icons/Rectangle 1008.png");
    .belanja-murah-product{
      max-width: 460px;
      min-height: 311px;
      .icon-belanja-murah{
        height: 305px;
      }
      .swiper-slide{
        height: 305px;
      }
      .view-all{
        background-color: white;
        height: 305px;
        border-radius: 8px;

        .icon-view-all{
          width: 6vw;
          position: absolute;
          right: 0;
        }
        .lihat-semua{
          position: absolute;
          top: 45%;
          left: 25%;
          text-align: center;
          cursor: pointer;
          .title-lihat-semua{
            color: #EA217A;
            font-size: 10pt;
            font-weight: 600;
          }
          .icon-lihat-semua{
            width: 35px;
            height: 35px;
            border-radius: 100%;
            background-image: linear-gradient(to right, rgb(255, 85, 0) ,#cc0a78);
            margin: 5px auto;
            color: white;
          }
          .arrow-right{
            margin-top: 1.2vh;
            font-size: 14px;
          }
        }
      }
      .swiper-slide{
        max-width: 170px;
        text-align: center;
      }
      .product-card {
        cursor: pointer;
        display: flex;
        position: relative;
        flex-direction: column;
        border-radius: 8px;
        height: 100%;
        width: 100%;
        background-color: white;
        margin-bottom: 5px;
        .product-rating {
          width: 100%;

          .anticon{
            color: gold;
            font-size: 12px;
          }
          .ant-typography{
            font-size: 12px;
          }
        }
        .product-image-section {
          overflow: hidden;
          border-radius: 8px;

          .product-image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 150px;




            @media screen and (min-width: 420px) {
              height: 160px;
            }

            @media screen and (min-width: 520px) {
              height: 160px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .section-almost-done {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            gap: 10px;
            padding: 5px;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 0px 0px 8px 8px;

            .icon-duration {
              width: 15px;
              height: 15px;
            }
          }
        }

        .discount-section {
          position: absolute;
          top: 12px;
          z-index: 1;
          background: url("../img/box-discount.png") no-repeat;
          background-size: cover;
          width: 40px;
          height: 25px;
          display: flex;
          padding-left: 5px;
          align-items: center;
          font-size: 10px;
          font-weight: bold;
          gap: 2px;
          color: black;
          left: -1px;

          .ant-typography {
            font-size: 14px;
            color: white;
            font-weight: bold;
          }
          .percent{
          font-size: 9px;
          color: white;
          margin-bottom: 3px;
          font-weight: 600;
          }
        }
        .product-info {
          padding: 5px 10px ;

          .product-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2;
            overflow: hidden;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .product-price-section {
            justify-content: flex-start;
            gap: 0px;
            background: none!important;
            padding: 0;
            margin: 0;
            border-radius: 0;
            align-items: start;

            .product-real-price {
              color: #ec1652;
              font-size: 17px;
              font-weight: 600;
              margin-right: 5px;
            }

            .product-discount-price {
              font-size: 10px;
              text-decoration: line-through;
              color: #949494;
              line-height: 23px;
            }
          }
          .product-card-kurang{
            .ant-col{
              padding-top: 0!important;
              margin-top: -5px;
              margin-bottom: 5px;
            }
          }
          .ant-col{
            padding-top: 0!important;
          }
          .ant-space-vertical{
            gap:0!important;
          }
        }
      }

    }
  }
  .Top-UP{
    h4.ant-typography{
      font-weight: bold!important;
    }
    .typography h4{
      font-weight: bold;
    }
    .input-topUP{
      height: 45px;
      border: 2px solid #d9d9d9;
      border-radius: 10px;
    }
    .feedback-input{
      margin-left: 12px;
      font-size: 12px;
      color: #db0112;
    }
    .row-provider{
      display: flex;
      justify-content: space-between;
      text-align: center;
    }
    .logo-provider{
      height: 50px;
      width:  auto ;
      margin-bottom: 20px;

    }


    .topUP-button{
      border-radius: 10px;
      height: 40px;
      border: none;
      background-image: linear-gradient(to right, rgb(255, 85, 0) ,#cc0a78);
      font-weight: 600;
      margin-top: 15px;
      color: white;
    }
    .ant-btn-primary[disabled]{
      opacity: 0.5;
    }
    .notif{
      background: rgba(163, 147, 251, 0.2);
      padding: 15px 15px 15px 0;
      border-radius: 10px;


    }
    .notif-UL{
      list-style: decimal;
      @media screen and (max-width: 420px) {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
      }
    }

    .yellow {
    background: rgba(252, 211, 2, 0.4);
    padding: 10px 20px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #333333;
    }
    .row-payment{
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      border-radius: 10px;
      padding: 8px;
      .payment{
        padding:8px;
        .barcode{
          max-width: 420px;
          @media screen and (max-width: 400px) {
            max-width: 300px;
          }
        }
        .price{
          strong{
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
          }
        }
      }

      .payment-timer{
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 2px;
        color: white;
        background: #EC1652;
        border-radius: 60px;
        padding: 5px 10px 5px 15px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          margin-right: 5px;
        }
      }
      .ant-btn{
        margin-top: 15px;
      }
      .button-border{
        border: 1px solid #EA217A;
        border-radius: 60px;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #EA217A;
      }
      .button-primary{
        border: 1px solid #EA217A;
        background: #EA217A;
        border-radius: 60px;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: white;
      }

      .ant-statistic-content-value{
        color: white;
      }
    }
    .ant-collapse{
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      border-radius: 10px;
      .ant-collapse-header-text{
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #333333;
      }
    }
    .ant-collapse-content {
      border: none;
    }
    .ant-collapse-item{
      border: 1px solid #EEEEEE;
    }
    .ant-tabs-content-holder{
      padding: 0 15px;
    }
    .ant-tabs-nav-wrap{
      display: block;
      flex:100%;
      max-width: 100%;
    }
    .ant-tabs-tab{
      text-align: center;
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
      margin: 0;
    }
    .Sell-out-Tabs{
      .ant-tabs-tab{
        text-align: center;
        display: block;
        flex: 0 0 33%;
        max-width: 33%;
        margin: 0;
      }
    }
    .ant-tabs-nav-more{
      display: none;
    }
    .ant-tabs-tab-btn{
      font-weight: 600;
    }
    .ant-tabs-tab-btn:active{
      color: #EA217A;
      font-weight: 600;
    }
    .ant-tabs-tab-active{
      .ant-tabs-tab-btn {
        color: #EA217A;
        font-weight: 600;
      }
    }
    .ant-tabs-tab:hover{
      color: #EA217A;
      font-weight: 600;
    }
    .ant-tabs-ink-bar{
      background: #EA217A;
    }
    .input-option{
      .ant-select-selector{
        height: 45px;
        border-radius: 10px;
        border: 2px solid #d9d9d9;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-select-selection-placeholder{
        margin: auto;
      }
      .ant-select-arrow{
        top:40%
      }

    }

    .icon-dompet{
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    .row-icon-dompet{
      .ant-col{
        cursor: pointer;
        border-bottom: 1px solid #e2e2e2;
        padding: 10px 0;
        font-weight: 600;
      }
      a{
        color: #333333;
      }
    }
    .row-icon-voucher{
      display: flex;
      justify-content: space-between;
      .ant-col-24{
        padding: 10px;
        text-align: center;
        div {
          text-align: center;
          background-color: white;
          border-radius: 10px;
          margin-bottom: 5px;
        }

        .ant-typography{
          font-weight: 600;
          display: block;
        }
      }
      .icon-voucher{
        max-width: 210px;
        height: 108px;
        margin: 0 auto;
        display: block;
        @media screen and (max-width: 400px) {
          max-width: 150px;
          height: auto;
        }
      }

    }

    .row-icon-DompetEL{
      display: flex;
      justify-content: space-between;
      .ant-col-24{
        text-align: center;

        .ant-col{
          display: flex;
          justify-content: center;
          align-items: center;
          padding:0px 5px;
        }
        div {
          text-align: center;
          background-color: white;
          border-radius: 10px;
          margin-bottom: 5px;
          padding: 15px;
        }
        .DompetEL-text{
          padding: 10px 40px;
          @media screen and (max-width: 400px) {
            padding: 10px!important;
          }
          .pulsa{
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .ant-typography{
          display: block;
          padding: 1px;
        }
        .price{
          color: #cc0a78;
        }
        .icon-coin{
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
    }
    .row-icon-Lainnya{
      display: flex;
      .ant-col-6{
        padding: 10px;
        text-align: center;
        div {
          text-align: center;
          background-color: white;
          border-radius: 10px;
          margin-bottom: 5px;
          padding: 15px;
          height: 90px;
          margin: auto;
          display: grid;
          align-items: center;
        }

        .ant-typography{
          font-weight: 600;
          display: block;
        }
      }
      .icon-lainnya{
        width: 40px;
        height: auto;
        margin: 0 auto;
        display: block;
      }

    }
  }
  .logo-suffix{
    height: 50px;
    width:  auto ;
  }

  .ant-layout-content {
    background-color:#f6f6f6 ;
    max-width: 500px;
    width: 100%;
    margin-top: 64px;

    //Auth Page
    .container-auth {
      display: flex;
      flex-direction: column;
      padding: 20px;
      height: 100%;

      .title-auth {
        font-size: 18px;
        font-weight: bold;
        margin-top: 20px;
      }

      .desc-auth {
        font-size: 12px;
      }

      // Onboarding
      .logo-welcome {
        width: 100px;
      }

      .form-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin: 25px 0 auto;

        .ant-form-item {
          margin-bottom: 0;
        }
      }

      // Verification OTP
      .logo-verification {
        width: 100px;
        margin-top: 10px;
      }

      .otp-auth {
        display: flex;
        justify-content: space-between;
      }

      .otp-auth-desc {
        display: flex;
        justify-content: center;
        color: #0a4df4;
        font-size: 12px;
        margin-top: 25px;
      }

      // Login
      .pin-auth {
        display: flex;
        justify-content: space-evenly;
      }

      .pin-auth-desc {
        display: flex;
        justify-content: center;
        font-size: 12px;
        gap: 5px;
        margin-top: 25px;
      }

      // Register
      .ant-progress-text {
        font-size: 12px;
        font-weight: bold;
      }

      .title-register {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 2px;
      }

      .desc-register {
        font-size: 10px;
      }

      .form-register {
        margin: 25px 0 0;
        display: flex;
        flex-direction: column;
        height: 100%;

        .ant-row {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
        }

        .ant-form-item {
          margin: 0 0 15px;
          align-items: flex-start;

          &:nth-last-child(1) {
            margin: auto 0 0;
          }

          &:nth-last-child(2) {
            margin: 0 0 35px;
          }

          .ant-form-item-label {
            padding: 0;
            flex: none;

            > label {
              font-size: 12px;
              font-weight: bold;
            }
          }

          .ant-form-item-control {
            width: 100%;
            flex: none;
          }
        }
      }

      // Upload KTP
      .container-syarat-upload {
        margin: 35px 0 0;

        .syarat-upload-text {
          font-size: 11px;
          font-weight: normal;
          margin: 0 0 5px;
        }
      }

      .container-upload {
        display: flex;
        flex-direction: column;
        margin: 35px 0 0;
        height: 100%;

        .ant-form-item:nth-last-child(1) {
          margin: auto 0 0;
        }

        .ant-upload.ant-upload-select-picture-card {
          margin: 10px 0 0;
          width: 100%;
          height: 220px;
          border-radius: 8px;
          background-color: #f6f8fe;
          background-image: url("../img/icons/aksen-upload.png");
          background-size: cover;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
          border: 2px dashed #0a4df4;

          .icon-upload {
            display: flex;
            flex-direction: column;
            background: rgba(68, 126, 249, 0.2);
            border-radius: 8px;
            padding: 10px;
            gap: 6px;

            .logo-upload {
              width: 40px;
              height: 40px;
            }

            .text-upload {
              font-size: 12px;
              font-weight: bold;
              color: #0a4df4;
            }
          }
        }

        .ant-upload-list-picture-card-container {
          margin: 10px 0 0;
          width: 100%;
          height: 220px;
          border-radius: 8px;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
          border: 2px dashed #0a4df4;
        }

        .ant-upload-list-picture-card .ant-upload-list-item {
          background-color: #f6f8fe;
          background-image: url("../img/icons/aksen-upload.png");
          background-size: cover;
        }

        .ant-upload-list-item-error {
          border-radius: 8px;
        }
      }
    }

    .btn-auth {
      background: linear-gradient(94.67deg, #0a4df4 0%, #5d8af9 100%);
      border-radius: 8px;
      color: white;
      height: 40px;
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;

      &.disabled {
        opacity: 0.3;
      }
    }

    .container-index {
      padding: 20px 20px;
      flex-direction: column;
      width: 100%;
      z-index: 0;
      h4 {
        font-weight: bold;
      }
    }

    .container-content {
      flex-direction: column;
      padding: 20px;
      width: 100%;
    }

    .index-header {
      height: fit-content;
      width: 100%;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.03);
      height: 64px;

      .ant-typography {
        margin: 0;
      }
    }

    .input-form {
      padding: 14px 16px;
      border: 1px solid #dddddd;
      border-radius: 8px;
    }

    .select-form {

      .ant-select-selector {
        border: 2px solid #dddddd;
        border-radius: 10px;
        padding: 7px 10px;
        height: auto;
        .ant-select-selection-placeholder{
          color: #333333;
        }

        .ant-select-selection-search {
          top: auto;
          bottom: auto;
        }
      }
    }
    .ant-select-arrow {
      color: #333333!important;
    }
    // Home Page
    .linear-background {
      position: absolute;
      background: linear-gradient(
        90deg,
        rgba(158, 31, 100, 1) 0%,
        rgba(236, 22, 82, 1) 100%
      );
      max-width: 500px;
      width: 100%;
      height: 135px;
      top: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .section-logo-bantuan {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      .logo-bantuan {
        padding: 8px;
        width: 36px;
        background-color: white;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        border-radius: 100%;
      }

      .ant-typography {
        color: #666666b2;
      }
    }

    .banner-home {
      display: flex;
      flex-direction: column;
      position: relative;
      background: linear-gradient(283.73deg, #0064d4 1.41%, #4a9ffd 95.24%);
      border-radius: 12px;
      padding: 20px 16px;
      margin: 25px 0 15px;
      height: 170px;

      .banner-title {
        font-size: 17px;
        width: 230px;
        color: white;
        margin-bottom: 12px;
      }

      .banner-desc {
        font-size: 12px;
        width: 180px;
        color: white;
      }

      .banner-icon {
        position: absolute;
        width: 100px;
        bottom: 15px;
        right: 12px;
      }

      .background-banner {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .container-info-saldo {
      background-color: white;
      border-radius: 16px;
      padding: 8px 16px;
      margin-bottom: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .slick-dots{
        margin: 0px;
        justify-content: flex-start;
        top:0;
      }
      .slick-dots li {
        height: 1px;
        width: 20px;
        margin: 0 2px;
      }
      .slick-active{
        width: 20px;
         button{
          background-color: #EA217A!important;
        }

      }
      .slick-dots li button::after{

      }
      .slick-dots li button{
        height: 2px;
        background-color: #BBBBBB;
        opacity: 1;
      }
      .slick-slider{
        padding: 15px;
      }
      .section-top {
        position: relative;

        .background-linear {
          position: absolute;
          right: -16px;
          top: -5px;
          height: 88px;
        }

        .section-saldo {

          .Saldo-saya{
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
          }
          .right-arrow {
            width: 12px;
            margin-left: 10px;
          }

          .nominal-saldo {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            float: left;
            margin:0;
          }
          .ant-col {
           display: flex;
          }

        }
        .col-transfer{
          display: flex;
          justify-content: center;
          align-items: center;
         }
        .ant-col {
          z-index: 10;
        }

        .section-menu {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 6px;

          .icon-menu {
            width: 30px;
          }

          .text-menu {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            color: #333333;
          }
        }
      }

      .ant-divider-horizontal {
        margin: 8px 0;
      }
      .icon-poin-saya {
        width: 17px;
        margin-right: 6px;
        float: left;
      }
    }

    .container-verify-info {
      background: rgba(68, 126, 249, 0.2);
      border-radius: 8px;
      padding: 14px 16px;
      margin-bottom: 15px;

      &.fail {
        background: rgba(255, 0, 0, 0.1);
      }

      .verify-icon {
        width: 32px;
      }

      .verify-desc {
        font-size: 12px;
      }

      .verify-link {
        font-weight: bold;
        color: #EA217A;
      }
    }

    .banner-home-scholarship {
      display: flex;
      flex-direction: column;
      position: relative;
      background: linear-gradient(76.9deg, #ad99fd 11.2%, #608cfa 100%);
      border-radius: 12px;
      padding: 12px 16px;
      margin: 8px 0 16px;
      overflow: hidden;

      .banner-title {
        font-size: 12px;
        color: white;
        font-weight: bold;
        z-index: 1;
        margin-bottom: 2px;
      }

      .banner-desc {
        font-size: 10px;
        width: 230px;
        color: white;
        z-index: 1;
      }

      .banner-icon {
        position: absolute;
        width: auto;
        height: 100%;
        bottom: 0;
        right: 0;
        z-index: 1;
      }

      .background-banner {
        position: absolute;
        top: -70px;
        right: -150px;
      }
    }

    .product-section {
      text-align: center;
      gap: 10px;
      width: 100%;

      .product-card {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        border-radius: 16px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        height: 100px;
        @media screen and (max-width: 400px) {
          height: 70px ;
        }

        .product-section-icon {
          .product-icon {
            width: 70px;
            @media screen and (max-width: 400px) {
              max-width: 50px;
            }
          }
        }


      }
      .product-title {
        font-size: 14px;
        width: 70px;
      }
    }

    .subcategory-product-card {
      display: flex;
      align-items: center;
      gap: 20px;

      .image-section {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        height: 45px;
        width: 45px;

        .product-image {
          width: 35px;
        }
      }

      .product-title {
        font-size: 14px;
        font-weight: bold;
        color: black;
      }
    }

    // Bantuan
    .visual-bantuan {
      width: 250px;
      height: auto;
      margin-bottom: 25px;
    }

    .menu-bantuan-section {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      padding: 20px 16px;
      width: 100%;

      .menu-bantuan {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 15px;
        height: 45px;
        position: relative;

        .icon-bantuan {
          width: 30px;
        }

        .title-bantuan {
          font-size: 16px;
          margin-bottom: 4px;
        }

        .desc-bantuan {
          font-size: 12px;
        }

        .icon-navigasi {
          position: absolute;
          right: 0;
          width: 15px;
          transform: rotate(180deg);
        }
      }

      .ant-divider-horizontal {
        margin: 20px 0;
      }
    }

    .visual-artikel {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }

    .container-review {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .title-review {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .review-section {
        display: flex;
        gap: 20px;
        align-items: center;

        .icon-thumbs {
          width: 25px;
          margin-right: 5px;
        }
      }
    }

    // Akun Page
    .section-pelengkap-data {
      flex-wrap: nowrap;
      gap: 15px;
      padding: 12px;
      background-color: rgba(68, 126, 249, 0.2);
      border-radius: 8px;
      width: 100%;
      margin-bottom: 25px;

      .text-info {
        font-size: 11px;
      }

      .persentase {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background: linear-gradient(89.97deg, #0a4df4 0%, #8faefa 99.98%);
        border-radius: 6px;

        .ant-typography {
          color: white;
          font-weight: bold;
        }
      }
    }

    .section-info-user {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: 15px;
      margin-bottom: 25px;

      .user-avatar {
        width: 55px;
        height: 55px;
      }

      .user-name {
        gap: 10px;
        align-items: center;
        margin-bottom: 5px;

        h5 {
          margin: 0;
        }

        .icon-navigasi {
          width: 15px;
          transform: rotate(180deg);
        }
      }
    }

    .section-poin-user {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      padding: 15px;
      position: relative;
      overflow: hidden;

      .background-poin {
        position: absolute;
        top: 0;
        right: -2px;
        height: 100%;
      }

      .icon-poin {
        width: 34px;
        margin-right: 12px;
      }

      .icon-navigasi {
        width: 15px;
        margin-left: 12px;
        transform: rotate(180deg);
      }
    }

    .section-menu {
      flex-direction: column;
      gap: 15px;

      .ant-divider-horizontal {
        background: #e0e0e01a;
      }
    }

    .option-menu {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      padding: 22px 16px;
      flex-wrap: nowrap;
      position: relative;
      align-items: center;

      .icon-menu {
        width: 34px;
        height: auto;
      }

      .title-menu {
        font-size: 16px;
        margin-bottom: 2px;
      }

      .badge-new {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #65d835;
        padding: 0 16px;
        border-radius: 50px;

        .ant-typography {
          font-size: 10px;
        }
      }

      .desc-menu {
        font-size: 12px;
      }

      .icon-navigasi {
        width: 15px;
        transform: rotate(180deg);
      }
    }

    // Transfer Page
    .section-sisa-saldo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      gap: 12px;
      position: relative;
      overflow: hidden;

      .bg-linear {
        position: absolute;
        height: 100%;
        top: 0;
        right: -6px;
      }

      h1 {
        font-size: 20px;
        color: #0a4df4;
        margin: 0;
      }
    }

    .form-transfer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 25px 0 auto;
      flex-grow: 1;

      .ant-row {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }

      .ant-form-item {
        margin: 0 0 15px;
        align-items: flex-start;

        &:nth-last-child(1) {
          margin: auto 0 0;
        }

        .ant-form-item-label {
          padding: 0;
          flex: none;

          > label {
            font-size: 12px;
            font-weight: bold;
          }
        }

        .ant-form-item-control {
          width: 100%;
          flex: none;

          .ant-input-prefix {
            margin-right: 8px;
          }
        }
      }
    }

    // Transaksi Page
    .section-transaksi {
      flex-direction: column;
      padding: 15px 20px 84px;
      background: linear-gradient(
        180deg,
        #f7f9fa 0%,
        rgba(247, 249, 250, 0) 100%
      );
    }

    .section-option-status {
      overflow: auto;
      gap: 10px;
      margin-bottom: 20px;

      .option-status {
        border: 1px solid #0a4df4;
        border-radius: 100px;
        padding: 8px 24px;
        background: rgba(68, 126, 249, 0.1);

        .ant-typography {
          color: #0a4df4;
        }
      }
    }

    .skeleton-transaksi {
      position: relative;
      display: flex;
      align-items: center;

      .ant-skeleton-header {
        position: absolute;
        display: inline;
        right: 25px;
        padding: 0;

        span {
          border-radius: 8px;
        }
      }

      .ant-skeleton-content {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        .ant-skeleton-title {
          width: 100%;
          height: 121px;
          border-radius: 8px;
          margin: 0;
        }

        .ant-skeleton-paragraph {
          width: 55%;
          position: absolute;
          margin: 0;
          left: 25px;
        }
      }
    }

    .section-transaksi-list {
      .ant-list-item {
        padding: 5px 0;
      }

      .empty-transaksi {
        display: flex;
        flex-direction: column;
        align-items: center;

        .visual-empty {
          width: 250px;
          height: auto;
          margin-bottom: 25px;
        }

        span {
          font-size: 12px;
        }
      }
    }

    .list-transaksi-card {
      display: flex;
      flex-direction: column;
      padding: 15px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      gap: 10px;

      .invoice {
        font-size: 12px;
        font-weight: bold;
      }

      .created {
        font-size: 12px;
        color: #bcbcbc;
      }

      .phone {
        color: #909090;
      }

      .indosat {
        display: flex;
        align-items: center;
        height: 30px;

        img {
          width: 25px;
          height: auto;
        }
      }

      .telkomsel {
        display: flex;
        align-items: center;
        height: 30px;

        img {
          width: auto;
          height: 100%;
        }
      }

      .status {
        font-weight: bold;
        font-size: 12px;
      }

      .SUCCESS {
        color: #48ba27;
      }

      .PENDING {
        color: #2f80ec;
      }

      .FAILED {
        color: #db0112;
      }
    }

    .detail-transaksi-notification {
      display: flex;
      align-items: center;
      background: rgba(68, 126, 249, 0.2);
      border-radius: 4px;
      margin-bottom: 15px;
      position: relative;

      .icon-info {
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        opacity: 0.3;
      }

      .ant-typography {
        margin: 10px;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.5;
      }
    }

    .section-detail-transaksi {
      padding: 15px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      position: relative;
      overflow: hidden;

      .linear-background {
        position: absolute;
        top: initial;
        bottom: -3px;
        left: -15px;
        width: 100%;
        height: auto;
        background: none;
      }

      .header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;

        .indosat {
          display: flex;
          align-items: center;
          height: 30px;

          img {
            width: 30px;
            height: auto;
          }
        }

        .telkomsel {
          display: flex;
          align-items: center;
          height: 30px;

          img {
            width: auto;
            height: 100%;
          }
        }
      }

      .title {
        font-size: 12px;
        color: #666666;
        font-weight: bold;
      }

      .status {
        font-weight: bold;
        font-size: 12px;
      }

      .SUCCESS {
        color: #48ba27;
      }

      .PENDING {
        color: #2f80ec;
      }

      .FAILED {
        color: #db0112;
      }

      .content {
        font-size: 12px;
        font-weight: bold;
      }

      .total {
        font-size: 12px;
        font-weight: bold;
      }

      .detail {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .ant-divider-horizontal {
        margin: 16px 0;
      }

      .price {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .btn-komisi {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #0a4df5;
        border-radius: 8px;
        padding: 10px 14px;
        margin-top: 10px;

        span {
          color: #0a4df5;
          font-size: 10px;
          font-weight: bold;
          font-family: "Gotham-Book";
        }
      }
    }

    .section-option-detail-transaksi {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;

      .option-detail-transaksi {
        background: linear-gradient(
          92.82deg,
          rgba(10, 77, 244, 0.1) 0.7%,
          rgba(10, 77, 244, 0.05) 99.53%
        );
        border-radius: 8px;
        padding: 12px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;

        img {
          width: 20px;
          height: auto;
        }

        .ant-typography {
          font-size: 12px;
          font-weight: bold;
          color: #0a4df4;
        }
      }

      .bantuan {
        font-size: 12px;
        font-weight: bold;
        text-align: center;

        a {
          text-decoration: underline;
        }
      }
    }

    // Pesan
    .option-pesan {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &.ant-menu::before,
      &.ant-menu-horizontal::after,
      &.ant-menu::after {
        content: initial;
      }

      .ant-menu-item {
        text-align: center;
        padding: 0;

        &.ant-menu-item-selected::after {
          width: 100%;
          left: 0;
        }

        span {
          font-weight: bold;
        }
      }

      .ant-menu-submenu {
        display: none;
      }
    }

    .section-pesan-list {
      .ant-list-item {
        padding: 5px 0;
      }

      .skeleton-pesan {
        position: relative;
        display: flex;
        align-items: center;

        .ant-skeleton-header {
          position: absolute;
          display: inline;
          left: 25px;
          padding: 0;

          span {
            width: 50px;
            height: 50px;
            border-radius: 12px;
          }
        }

        .ant-skeleton-content {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;

          .ant-skeleton-title {
            width: 100%;
            height: 100px;
            border-radius: 8px;
            margin: 0;
          }

          .ant-skeleton-paragraph {
            width: 60%;
            position: absolute;
            margin: 0;
            left: 100px;
          }
        }
      }

      .list-pesan-card {
        display: flex;
        align-items: center;
        height: 100px;
        padding: 15px;
        gap: 12px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        border-radius: 10px;

        .img-card {
          width: 45%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 8px;

          img {
            width: auto;
            height: 100%;
          }
        }

        .text-card {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;

          .title {
            font-size: 16px;
            font-weight: bold;
          }

          .date {
            font-size: 12px;
            font-weight: bold;
            color: #bcbcbc;
          }

          .desc {
            font-size: 11px;
            color: #909090;
          }
        }
      }
    }

    .section-detail-pesan {
      .img-visual {
        width: 100%;
        height: 160px;
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 15px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .section-under-visual {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section-syarat:nth-last-child(1) {
          padding-left: 15px;
          border-left: 1px solid #bbbbbb;
        }
      }

      .section-syarat {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .title {
          font-size: 12px;
          font-weight: bold;
        }

        .berlaku {
          font-size: 14px;
          font-weight: bold;
          color: #0a4df4;
        }
      }

      .section-content-detail {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .title {
          font-size: 16px;
          font-weight: bold;
        }

        .desc {
          font-size: 12px;
          color: #666666;
        }
      }
    }

    // Akun Saya
    .section-info-user {
      .ant-image {
        width: 55px;
        height: 55px;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .user-avatar {
          width: 100%;
          height: auto;
        }
      }

      .upload-avatar {
        .text-upload {
          font-size: 12px;
          font-weight: bold;
          color: #0064d4;
          text-decoration: underline;
        }

        .ant-upload-list {
          display: none;
        }
      }
    }

    .form-akun-saya {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .ant-row {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }

      .ant-form-item {
        margin: 0 0 15px;
        align-items: flex-start;

        &:nth-last-child(1) {
          margin: auto 0 0;
        }

        .ant-form-item-label {
          padding: 0;
          flex: none;

          > label {
            font-size: 12px;
            font-weight: bold;
          }
        }

        .ant-form-item-control {
          width: 100%;
          flex: none;
        }
      }
    }

    // Data Outlet
    .form-outlet {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .ant-row {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }

      .ant-form-item {
        margin: 0 0 15px;
        align-items: flex-start;

        &:nth-last-child(1) {
          margin: auto 0 0;
        }

        .ant-form-item-label {
          padding: 0;
          flex: none;

          > label {
            font-size: 12px;
            font-weight: bold;
          }
        }

        .ant-form-item-control {
          width: 100%;
          flex: none;
        }
      }

      .outlet-map {
        position: relative;
        display: flex;
        justify-content: center;

        .ant-form-item-control-input {
          overflow: hidden;
          border-radius: 10px;
          box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.03);
        }

        .overlay-text {
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: white;
          color: #0a4df4;
          font-weight: bold;
          font-size: 12px;
          text-align: center;
          padding: 6px;
          z-index: 1;
          border: 1px solid #dddddd;
        }
      }
    }

    .search-map {
      position: absolute;
      top: 20px;
      z-index: 1;
      width: 90%;

      input {
        width: 100%;
        padding: 12px 13px;
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 8px;
        color: black;
      }
    }

    .section-detail-alamat {
      width: 100%;
      background-color: white;
      padding: 15px;
      border-radius: 24px 24px 0 0;
      display: flex;
      flex-direction: column;
      gap: 25px;
      animation: popup 0.5s forwards;

      .detail-alamat {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .alamat {
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
          border-radius: 8px;
          padding: 8px;
          font-size: 12px;
        }
      }
    }

    @keyframes popup {
      0% {
        position: absolute;
        bottom: -250px;
      }
      100% {
        position: absolute;
        bottom: 0;
      }
    }

    // Wispay Sales
    .visual-wispay-sales {
      width: 250px;
      height: auto;
      margin: 25px 0;
    }

    .text-visual {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 10px;

      .title {
        font-weight: bold;
      }

      .desc {
        font-size: 12px;
      }
    }

    .form-wispay-sales {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 25px 0 auto;
      width: 100%;
      flex-grow: 1;

      .ant-row {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }

      .ant-form-item {
        margin: 0 0 15px;
        align-items: flex-start;

        &:nth-last-child(1) {
          margin: auto 0 0;
        }

        .ant-form-item-label {
          padding: 0;
          flex: none;

          > label {
            font-size: 12px;
            font-weight: bold;
          }
        }

        .ant-form-item-control {
          width: 100%;
          flex: none;

          .ant-input-prefix {
            margin-right: 8px;
          }
        }
      }
    }

    .icon-wispay-sales {
      width: 100px;
      margin-bottom: 15px;
    }

    // Atur Komisi
    .container-atur-komisi {
      display: flex;
      flex-direction: column;
      width: 100%;

      .ant-divider-horizontal {
        margin: 12px 0;
      }

      .skeleton-atur-komisi {
        position: relative;
        display: flex;
        align-items: center;

        .ant-skeleton-header {
          position: absolute;
          display: inline;
          right: 15px;
          padding: 0;
          height: 16px;

          span {
            width: 50px;
            height: 16px;
            border-radius: 8px;
          }
        }

        .ant-skeleton-content {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;

          .ant-skeleton-title {
            width: 100%;
            height: 50px;
            border-radius: 8px;
            margin: 0;
          }

          .ant-skeleton-paragraph {
            width: 40%;
            position: absolute;
            margin: 0;
            left: 15px;

            li {
              border-radius: 8px;
            }
          }
        }
      }

      .section-atur-komisi-list {
        .ant-list-item {
          padding: 6px 0;
        }
      }

      .card-atur-komisi {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        border-radius: 10px;

        .ant-typography {
          font-size: 12px;
        }

        .icon-navigasi {
          width: 12px;
          transform: rotate(180deg);
        }
      }
    }
  }
}

.ant-modal-confirm {
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-body {
      padding: 15px;
    }
  }

  .ant-modal-close-x {
    width: 65px;
    height: auto;
    line-height: 65px;
    font-size: 20px;
  }

  &.hide-button {
    .ant-modal-content {
      border-radius: 12px;
      .ant-modal-body {
        padding: 15px 0 25px;

        .kode-qr-img {
          width: 250px;
        }

        .kode-qr-title {
          font-size: 20px;
          margin: 0;
        }

        .kode-qr-user {
          font-size: 14px;
        }

        .ant-divider-horizontal {
          margin: 16px 0 0;
        }
      }
    }

    .ant-modal-confirm-btns {
      display: none;
    }
  }
}

.ant-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
}

.modal-komisi {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 0 0 15px;

    .modal-title {
      font-size: 20px;
      margin: 15px 0 0;
      text-align: center;
    }

    .ant-divider-horizontal {
      margin: 16px 0;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      padding: 0 20px;

      .detail-transaksi-notification {
        display: flex;
        align-items: center;
        background: rgba(68, 126, 249, 0.2);
        border-radius: 4px;
        margin-bottom: 15px;
        position: relative;

        .icon-info {
          position: absolute;
          top: 0;
          left: 0;
          width: 15px;
          opacity: 0.3;
        }

        .ant-typography {
          margin: 10px;
          font-size: 10px;
          font-weight: bold;
          line-height: 1.5;
        }
      }

      .detail {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
          font-size: 12px;
          color: #666666;
          font-weight: bold;
        }

        .content {
          font-size: 12px;
          font-weight: bold;
        }

        .section-komisi {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .input-form {
            width: 170px;
            padding: 7px 6px;
            border: 1px solid #dddddd;
            border-radius: 8px;
            font-size: 12px;
            text-align: right;
          }
        }
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 20px;

    .total-harga {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .title {
        font-size: 12px;
        color: #666666;
        font-weight: bold;
      }

      .total {
        font-size: 16px;
        font-weight: bold;
        color: #0a4df4;
      }
    }

    .btn-submit {
      background: linear-gradient(94.67deg, #0a4df4 0%, #5d8af9 100%);
      border-radius: 8px;
      color: white;
      height: 40px;
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.modal-tanggal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 0 0 15px;

    .modal-title {
      font-size: 20px;
      text-align: center;
      margin: 15px 0 0;
    }

    .ant-divider-horizontal {
      margin: 16px 0;
    }

    .section-option-tanggal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 0 20px;
      gap: 15px;

      .ant-radio-wrapper {
        margin: 0;
      }
    }

    .tanggal-transaksi {
      margin: 10px 15px 0;
    }
  }

  .btn-submit {
    background: linear-gradient(94.67deg, #0a4df4 0%, #5d8af9 100%);
    border-radius: 8px;
    color: white;
    height: 40px;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-modal-confirm-body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .ant-modal-confirm-body {
    width: 100%;

    .anticon {
      display: none;
    }

    .ant-modal-confirm-title {
      font-weight: bold;
    }

    .ant-modal-confirm-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;

      .confirm-modal-logo {
        width: 250px;
        margin-top: 20px;
      }

      .confirm-modal-title {
        font-size: 16px;
        font-weight: bold;
        margin: 30px 0 10px;
      }

      .confirm-modal-desc {
        font-size: 12px;
      }
    }
  }

  .ant-modal-confirm-btns {
    width: 100%;
    margin-top: 16px;

    .ant-btn-primary {
      background: linear-gradient(94.67deg, #0a4df4 0%, #5d8af9 100%);
      border-radius: 8px;
      border: none;
      height: 40px;
      width: 100%;
    }
  }
}
.ant-drawer-content-wrapper{
  height: 270px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: none!important;
  .ant-drawer-content{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  .ant-drawer-header{
    border-bottom: none;
  }
  .ant-drawer-body{
    padding: 5px 0px;
  }
  .ant-row{
    margin: 0 24px;
  }
  .border-top{
    border-top: 1px solid #EEEEEE;
    padding-top: 24px;
  }
  .ant-divider {
    border-top: 1px solid #EEEEEE;
  }
  .notif{
    background: rgba(252, 211, 2, 0.4);
    padding: 8px 15px;
    border-radius: 10px;
    .yellow {
      padding: 10px 20px;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #333333;
      }
  }
  .ant-drawer-header-title{
    text-align: center;
  }
  .ant-drawer-title{
    font-weight: bold;
    font-size: 18px;
  }
  .price-paket{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-size: 18px;
    line-height: 23px;
    color: #EA217A;
    strong{
      font-size: 18px;
      font-weight: 700;
    }
  }
  .ant-typography{
    font-size: 14px;
  }
  .lanjut-bayar{
    background-image: linear-gradient(to right, rgb(255, 85, 0) ,#cc0a78);
    border-radius: 8px;
    color: white;
    padding: 5px 20px;
    font-weight: bold;
    height: 40px;
  }
  .lanjut-bayar:hover{
    border:1px solid #EC1652;
  }

}
.wave-hand{
  font-size: x-large;
}
.detail-paket-data{
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content:flex-start;
  align-content: center;
  padding: 15px;
}
.Asuransi{
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #333333;
    text-shadow: 0 0 0.25px #333333;
  }
  .ant-tabs-tab-btn{
    font-weight: 600;
  }
  .ant-tabs-tab-btn:active{
    color: #EA217A;
    font-weight: 600;
  }
  .ant-tabs-tab-active{
    .ant-tabs-tab-btn {
      color: #EA217A;
      font-weight: 600;
    }
  }
  .ant-tabs-tab:hover{
    color: #EA217A;
    font-weight: 600;
  }
  .ant-tabs-ink-bar{
    background: #EA217A;
    width: 20px!important;
  }

}
.Manfaat-UL {
  margin-left: 0;
  padding: 0 10px;
  li{
    margin: 0;
	  padding: 20px 0 0 70px;
    list-style: none;
    background-repeat: no-repeat;
    background-position:0px 30px;
    background-size: 50px auto;
  }
  li:nth-child(1){
    background-image:url("../img/insurance/cari.png");
  }
  li:nth-child(2){
    background-image:url("../img/insurance/isidata.png");
  }
  li:nth-child(3){
    background-image:url("../img/insurance/terlindungi.png");
  }
}
.Klaim-UL {
  margin-left: 0;
  padding: 0 10px;
  li{
    margin: 0;
	  padding: 20px 0 0 70px;
    list-style: none;
    background-repeat: no-repeat;
    background-position:0px 30px;
    background-size: 50px auto;
  }
  li:nth-child(1){
    background-image:url("../img/insurance/invoice.png");
  }
  li:nth-child(2){
    background-image:url("../img/insurance/petunjuk.png");
  }
  li:nth-child(3){
    background-image:url("../img/insurance/bataswaktu.png");
  }
  li:nth-child(4){
    background-image:url("../img/insurance/formulir.png");
  }
}
.cooming-soon{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .comingsoon-img{
    width: 136px;
    margin: 20px 0;
  }
}
.coming-title{
  @media screen and (max-width: 400px) {
    font-size: 13px;
  }
}
.comingsoon-logo{
  width: 135px;
  margin: 30px 10px;
}
.row-bottom{
  position: absolute;
  bottom: 10px;
  width:100%;
}
.logo-kirim-tunai{
  height: 20px;
}
.icon-steaming{
  height: 40px;
  margin: 20px;
}
.lihat-disini{
  font-weight: 600;
  text-decoration: underline;
  color: #EE4C24;
}
.sportify-drawer{
  .ant-drawer-content-wrapper{
    height: 200px!important;
  }
}
.icon-telkom{
  width: 80px;
  margin: 20px;
}
.section-header{
  padding: 10px 10px 0px 10px!important;
  .ant-typography{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
  }
  .title-section{
    text-transform: capitalize;
  }
}
.section-item {
  display: block!important;
  align-items: center;
  float: left;
  height: 110px!important;
  padding: 0!important;
  border-bottom:none!important;
  margin-bottom: 30px;
  flex: 0 0 25%;
  max-width: 25%;
}
.section-item-container{
  border:none!important;
  display: flex;
  flex-flow: row wrap;
  background:none!important;
  margin-bottom: 20px;
}

.loading-spin{
  display: flex;
  background-color: black!important;
  opacity: 0.5;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 1002;
    top: 0;
    left: 0;
}
.spin-area{
  background-color:white;
  width: 80px;
  height: 80px;
  display: flex;
  position: absolute;
  top: 300px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 1003;
  left: 47.5%;
  @media screen and (max-width: 400px) {
    left: 40%;
  }
}
.drawerleft{
  margin-left: -15px;
  @media screen and (max-width: 400px) {
    margin-left: 0px;
  }
}
.ant-spin-dot-item{
  background-color: #db0112;
}
.BPJS{
  .ant-select-clear{
    margin-top: -15px;
  }
}
.disable{
  opacity: 0.6;
  cursor: default!important;
}
.drawer-col-right{
  text-align: right;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.drawer-notification{
  margin-bottom: 10px;
  margin-top: -10px;
  background-color: #ffcce3;
  padding: 5px 5px 5px 20px;
  border-radius: 5px;
  font-size: 12px;
}
.ant-spin-spinning{
  margin: auto;
}
.infinite-scroll-component__outerdiv{
  width: 100%;
}
.email-input{
  margin-top: 20px;
  margin-bottom: 20px;
  .ant-typography{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    }
  .ant-input{
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    margin-top: 10px;
    padding: 10px;
  }
}
.info-misc{
  position: absolute;
  top: 0;
  left: 0;
  width: 23px;
  opacity: 0.2;
}
.col-left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.col-right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}
.col-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-center-spacebetween{
  display: flex;
  justify-content: space-between;
  gap: 70px;
  align-items: center;
}
.pln-token{
  width: 450px;
  @media screen and (max-width: 400px) {
    width: 300px;
  }
}
.error_message{
  .ant-message-notice-content{
    background-color: #db0112;
    color: white;
  }
  .ant-message-error .anticon {
    color: white;
  }
}
.text-screenshoot{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px!important;
  line-height: 150%;
  color: #333333;
}
.screenshoot-title{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px!important;
  line-height: 150%;
  color: #333333;
}
.share-div {
  margin-top: 20px;
  padding: 0 10px;
  img {
    display:block ;
    margin-bottom: 5px;
  }
}
.share-copy{
  @media screen and (max-width: 400px) {
   display: block;
   text-align: center;
   button{
    margin: 0 5px;
   }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.enable{
  cursor: pointer;
}
.dalam-kendala{
  color: #db0112;
}
.divide-menunggu-bayar{
  margin: 8px 0;
}
.Top-UP{
  .ant-form-item{
    margin-bottom: 5px;
  }
}
.option-disable{
  .ant-select-item-option-content{
    opacity: 0.6;
  }
}
.ant-drawer-close{
  position: absolute;
  right: 5px;
}
.Carabayar-divider{
  margin: 0!important;
}
.ML{
  display: flex;
  justify-content: space-between;
  gap: 5px;
  .ant-col-11 {
    flex: 0 0 48%;
    max-width: 48%;
  }
}
.PBB {
  .ant-select-selector{
    margin-bottom: 10px!important;
  }
}
.row-BPJS{
  display: none;
}
.displayNone{
  display: none;
}
.displayBlock{
  display: block!important;
}
.detail-transaksi{
  margin: 16px;
  padding: 8px 16px 0px 16px;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  background-image: url("../img/misc/aksen.png");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
  .ant-col {
    padding: 8px 0;
  }
  .detTrans-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    strong{
      font-size: 12px;
      color: #333333;
    }
  }
  .detTrans-text-bold{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  .detTrans-text-cash{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #333333;
  }
  .detTrans-text-metode{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
  .success{
    padding: 4px 10px;
    font-size: 12px;
    line-height: 100%;
    color: #2E9B1A;
    background: #DAF5D3;
    border-radius: 8px;
  }
  .pedding{
    padding: 4px 10px;
    font-size: 12px;
    line-height: 100%;
    color: #F3A205;
    background: #FDF4D0;
    border-radius: 8px;
    margin-left: 16px;
  }
  .ant-collapse {
    border:none!important;
    background: none!important;
  }
  .ant-collapse-item {
    border:none!important;
  }
  .ant-collapse-header{
    padding: 0!important;
  }
  .ant-collapse-header-text {
    color: #666!important;
  }
  .ant-collapse-content{
    background: none!important;
  }
  .ant-collapse-content-box{
    padding:8px 0 0 0;
  }
  .ant-collapse-arrow{
    right: 0!important;
  }
}

.rincian-transaksi{
  margin: 16px;
  padding: 8px 16px 0px 16px;
  border-radius: 8px;
  background-image: url("../img/misc/aksen.png");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
  .ant-col {
    padding: 8px 0;
  }
  .detTrans-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    strong{
      font-size: 12px;
      color: #333333;
    }
  }
  .icon-product{
    margin-bottom: 0.5em;
    margin-right: 10px;
    float: left;
  }
  .notifcol{
    padding: 0;
  }
  .violet{
    padding: 8px 4px 8px 20px!important;
  }
  .detTrans-text-bold{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  .detTrans-text-cash{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #333333;
  }
  .detTrans-text-metode{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
  .detTrans-text-total{
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 15px;
  }
  .ant-divider-horizontal{
    margin: 8px 0;
  }
  .success{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #48BA27;
    text-transform: uppercase;
  }
  .pedding{
    padding: 4px 10px;
    font-size: 12px;
    line-height: 100%;
    color: #F3A205;
    background: #FDF4D0;
    border-radius: 8px;
    margin-left: 16px;
  }

}

.Kirim-bukti{
  margin: 10px 14px;
  display: flex;
  justify-content: space-between;
  .ant-btn {
    height: auto;
  }
  .button-primary{
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #EA217A;
    background: rgba(254, 47, 162, 0.05);
    border-radius: 4px;
    border: none;
    padding: 11px;
  }
  .ant-col-11{
    margin-bottom: 15px;
    flex: 0 0 47%;
    max-width:47%;
  }
  .bantuan{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
    a{
      color:#EA217A;
      text-decoration: underline;
    }
  }
}

.lihat-rincian{
  margin: 10px 14px;
  .ant-btn {
    height: auto;
  }
  .button-primary{
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    background: linear-gradient(101.15deg, #EE4C24 3.75%, #EA217A 96.16%);
    border-radius: 4px;
    border: none;
    padding: 11px;
  }
  .ant-col-24{
    margin-bottom: 15px;
  }
  .bantuan{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
    a{
      color:#EA217A;
      text-decoration: underline;
    }
  }
}